import * as logger from "@/tools/logger.js";
import * as statusMapper from "@/service/error_request_mapper.js";

const _FILE_PATH = "service/establishments/legal_structure_type_service.js";
const _M_GET_ALL = "getAllLegalStructureType";
const _M_GET_BY_ID = "getLegalStructureTypeById";

/** Service d'accès à l'API des motifs maladie */
export class LegalStructureTypeService {
  constructor(api) {
    this.api = api;
  }

  /////////////////////////////// GET /////////////////////////////////////////
  /**
   * Récupère la liste des natures de structures juridique et les tri par ordre alphabétique
   */
  async getAll() {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_ALL,
      "Récupération de toutes les natures de structures juridiques."
    );

    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Récupère une nature de structure juridique par son id
   */
  async getById(id) {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_BY_ID,
      "Récupération d'une nature de structure juridique avec l'id : " + id
    );

    return this.api.getById(id).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// CREATE /////////////////////////////////////////

  async create(reason) {
    return this.api.create(reason).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(reason.id);
      converter.add409AlreadyExists(reason.name);
      converter.convert(error);
    });
  }

  /////////////////////////////// UPDATE /////////////////////////////////////////

  async update(reason) {
    return this.api.update(reason).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(reason.id);
      converter.convert(error);
    });
  }

  /////////////////////////////// DELETE /////////////////////////////////////////

  async delete(reasonId) {
    return this.api.delete(reasonId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(reasonId);
      converter.convert(error);
    });
  }
}
